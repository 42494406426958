import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

const ToolkitBusPage: FC<ToolskitBusPageTypes.ToolkitBusPageProps> = ({
  data: {
    toolkitBusPage: { urls, seo, title: pageTitle },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent className="partners-page">
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <h1>{pageTitle}</h1>
    </Layout>
  );
};
export const query = graphql`
  query ToolkitBusQuery($lang: String) {
    toolkitBusPage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      title
    }
  }
`;

export default ToolkitBusPage;
